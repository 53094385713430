import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useMemo } from 'react'

export default function Businesses(props) {
  const { t } = useTranslation('secrets')
  const { pageType } = props

  const customers = useMemo(() => {
    if (pageType === 'pm') {
      return ['vingroup', 'vincss', 'antsomi', 'savvycom', 'mio']
    }

    if (pageType === 'sm') {
      return ['pibridge', 'savvycom', 'mio']
    }

    return ['vingroup', 'vincss', 'antsomi', 'pibridge', 'savvycom', 'mio']
  }, [pageType])

  return (
    <>
      <section className="py-12 bg-block">
        <div className="section-container">
          <div className="mx-auto">
            <h2 className="text-xl font-semibold w-full text-center text-secondary-65 mb-6">
              {t('customers.title')}
            </h2>

            <div className={`flex justify-center flex-wrap gap-6 lg:gap-12`}>
              {customers.map((item) => (
                <div key={item} className="relative flex-1 max-w-[160px] min-w-[150px] max-h-20">
                  <Image
                    className="w-full h-auto max-h-28"
                    src={`/images/secrets/businesses/${item}-gray.svg`}
                    alt={item}
                    height={86}
                    width={131}
                  />
                  <Image
                    className="absolute z-10 opacity-0 hover:opacity-100 transition-opacity w-full h-auto left-0 max-h-28"
                    src={`/images/secrets/businesses/${item}.svg`}
                    alt={item}
                    height={86}
                    width={131}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
